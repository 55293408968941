import { Controller } from "stimulus";

export default class extends Controller {
  open(e) {
    let dropdown = this.element.querySelector(".user-dropdown");
    let trigger_button = this.element.querySelector(".user-menu-trigger");
    dropdown.classList.toggle("visible");
    trigger_button.classList.toggle("open");
    e.stopPropagation();

    const userDropdownCloseListener = (event) => {
      if (!event.target.closest('.user-dropdown')) {
        dropdown.classList.remove("visible");
        trigger_button.classList.toggle("open");
        removeUserDropdownCloseListener();
      }
    };

    const removeUserDropdownCloseListener = () => {
      document.removeEventListener("click", userDropdownCloseListener);
    };

    document.addEventListener("click", userDropdownCloseListener);
  }
}
