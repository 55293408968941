import {
    Application
} from "stimulus"

import '../styles/app.scss'

// import GlossaryController from "./controllers/glossary_controller"
import AccountMenuController from "./controllers/account_menu_controller"
import TopicSelectController from "./controllers/topic_select_controller"
import ChecklistController from "./controllers/checklist_controller"
import DragorderController from "./controllers/dragorder_controller"
import PrefixController from "./controllers/prefix_controller"
import SkiplinkController from "./controllers/skiplink_controller"
import GlossaryController from "./controllers/glossary_controller"
import ProgressBarController from "./controllers/progress_bar_controller"
import TableFilterController from "./controllers/table_filter_controller"

const application = Application.start()
// application.register("glossary", GlossaryController)
application.register("account-menu", AccountMenuController)
application.register("topic-select", TopicSelectController)
application.register("checklist", ChecklistController)
application.register("dragorder", DragorderController)
application.register("prefix", PrefixController)
application.register("skiplink", SkiplinkController)
application.register("glossary", GlossaryController)
application.register("progress-bar", ProgressBarController)
application.register("table-filter", TableFilterController)

var Turbolinks = require("turbolinks")
Turbolinks.start()
